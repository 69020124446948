import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'
import BottomCta from '../../components/bottom-cta'

// Load Page Content
import contentEn from '../../../content/pages/products/jascloud.en.yaml'
import contentId from '../../../content/pages/products/jascloud.id.yaml'
import OtherProducts from '../../components/other-products'
import StudyCaseSlider, { AllStudyCaseType } from '../../components/study-case'
import SEO from '../../components/seo'
import moment from 'moment'

export const query = graphql`
  query JascloudQuery($language: String!) {
    locales: allLocale(filter: { ns: { in: ["links", "products"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    voiceStudyCase: allStudyCasesVoice {
      edges {
        node {
          id
          languages {
            id
            description
            locale
            logo
            title
          }
        }
      }
    }
    crmStudyCase: allStudyCasesCrm {
      edges {
        node {
          id
          languages {
            id
            description
            locale
            logo
            title
          }
        }
      }
    }
    logo: file(relativePath: { eq: "images/jascloud/jascloud-logo.svg" }) {
      id
      publicURL
    }
    voiceFigure: file(relativePath: { eq: "images/jascloud/jascloud-voice-figure.png" }) {
      id
      childImageSharp {
        gatsbyImageData(quality: 90)
      }
    }
    officeNumberIcon: file(relativePath: { eq: "images/jascloud/jascloud-voice-icon-office-number.svg" }) {
      id
      publicURL
    }
    bizphoneIcon: file(relativePath: { eq: "images/jascloud/jascloud-voice-icon-bizphone.svg" }) {
      id
      publicURL
    }
    siptrunkIcon: file(relativePath: { eq: "images/jascloud/jascloud-voice-icon-siptrunk.svg" }) {
      id
      publicURL
    }
    virtuallineIcon: file(relativePath: { eq: "images/jascloud/jascloud-voice-icon-virtualline.svg" }) {
      id
      publicURL
    }
    directlineIcon: file(relativePath: { eq: "images/jascloud/jascloud-voice-icon-directline.svg" }) {
      id
      publicURL
    }
    wallboardIcon: file(relativePath: { eq: "images/jascloud/jascloud-voice-icon-wallboard.svg" }) {
      id
      publicURL
    }
    callRecordingIcon: file(relativePath: { eq: "images/jascloud/jascloud-voice-icon-call-recording.svg" }) {
      id
      publicURL
    }
    smsMarketingIcon: file(relativePath: { eq: "images/jascloud/jascloud-voice-icon-sms-marketing.svg" }) {
      id
      publicURL
    }
    figureCRM: file(relativePath: { eq: "images/jascloud/jascloud-figure-crm.svg" }) {
      id
      publicURL
    }
    crmBenefitIcon: file(relativePath: { eq: "images/jascloud/jascloud-crm-icon-benefit-point.svg" }) {
      id
      publicURL
    }
    pointIcon: file(relativePath: { eq: "images/jascloud/icon-point-1.svg" }) {
      id
      publicURL
    }
    point2Icon: file(relativePath: { eq: "images/jascloud/icon-point-2.svg" }) {
      id
      publicURL
    }
    point3Icon: file(relativePath: { eq: "images/jascloud/icon-point-3.svg" }) {
      id
      publicURL
    }
    point4Icon: file(relativePath: { eq: "images/jascloud/icon-point-4.svg" }) {
      id
      publicURL
    }

    contactCenterFullyCustomizableIcon: file(
      relativePath: { eq: "images/jascloud/jascloud-contact-center-icon-fully-customizable.svg" }
    ) {
      id
      publicURL
    }
    contactCenterApiIntegrationIcon: file(
      relativePath: { eq: "images/jascloud/jascloud-contact-center-icon-api-integration.svg" }
    ) {
      id
      publicURL
    }
    contactCenterAnalyticsReportIcon: file(
      relativePath: { eq: "images/jascloud/jascloud-contact-center-icon-analytics-report.svg" }
    ) {
      id
      publicURL
    }
    contactCenterInstantlyOmnichannelIcon: file(
      relativePath: { eq: "images/jascloud/jascloud-contact-center-icon-instantly-omnichannel.svg" }
    ) {
      id
      publicURL
    }
    contactCenterWallboardIcon: file(
      relativePath: { eq: "images/jascloud/jascloud-contact-center-icon-wallboard.svg" }
    ) {
      id
      publicURL
    }
    contactCenterChatBotIcon: file(relativePath: { eq: "images/jascloud/jascloud-contact-center-icon-chat-bot.svg" }) {
      id
      publicURL
    }
    contactCenterOmnichannelIcon: file(
      relativePath: { eq: "images/jascloud/jascloud-contact-center-icon-omnichannel.svg" }
    ) {
      id
      publicURL
    }
    contactCenterWorkplaceIcon: file(
      relativePath: { eq: "images/jascloud/jascloud-contact-center-icon-workplace.svg" }
    ) {
      id
      publicURL
    }
    contactCenterHumanResourcesIcon: file(
      relativePath: { eq: "images/jascloud/jascloud-contact-center-icon-human-resources.svg" }
    ) {
      id
      publicURL
    }
    contactCenterHardwareIcon: file(relativePath: { eq: "images/jascloud/jascloud-contact-center-icon-hardware.svg" }) {
      id
      publicURL
    }
    webBasedFigure: file(relativePath: { eq: "images/jascloud/jascloud-contact-center-figure-web-based.png" }) {
      id
      childImageSharp {
        gatsbyImageData(quality: 100)
      }
    }
    outsourcingFigure: file(relativePath: { eq: "images/jascloud/jascloud-contact-center-figure-outsourcing.png" }) {
      id
      childImageSharp {
        gatsbyImageData(quality: 100)
      }
    }
  }
`

interface JascloudPagePropsType {
  data: {
    voiceStudyCase: AllStudyCaseType
    crmStudyCase: AllStudyCaseType
    logo: PublicUrlType
    voiceFigure: IGatsbyImageData
    officeNumberIcon: PublicUrlType
    bizphoneIcon: PublicUrlType
    siptrunkIcon: PublicUrlType
    virtuallineIcon: PublicUrlType
    directlineIcon: PublicUrlType
    wallboardIcon: PublicUrlType
    callRecordingIcon: PublicUrlType
    smsMarketingIcon: PublicUrlType
    figureCRM: PublicUrlType
    crmBenefitIcon: PublicUrlType
    pointIcon: PublicUrlType
    point2Icon: PublicUrlType
    point3Icon: PublicUrlType
    point4Icon: PublicUrlType
    contactCenterFullyCustomizableIcon: PublicUrlType
    contactCenterApiIntegrationIcon: PublicUrlType
    contactCenterAnalyticsReportIcon: PublicUrlType
    contactCenterInstantlyOmnichannelIcon: PublicUrlType
    contactCenterWallboardIcon: PublicUrlType
    contactCenterChatBotIcon: PublicUrlType
    contactCenterOmnichannelIcon: PublicUrlType
    contactCenterWorkplaceIcon: PublicUrlType
    contactCenterHumanResourcesIcon: PublicUrlType
    contactCenterHardwareIcon: PublicUrlType
    webBasedFigure: IGatsbyImageData
    outsourcingFigure: IGatsbyImageData
  }
}

const JascloudPage = (props: JascloudPagePropsType): JSX.Element => {
  const { t, i18n } = useTranslation()
  const [content, setContent] = useState(contentEn)
  const {
    logo,
    voiceStudyCase,
    crmStudyCase,
    officeNumberIcon,
    bizphoneIcon,
    siptrunkIcon,
    virtuallineIcon,
    directlineIcon,
    wallboardIcon,
    callRecordingIcon,
    smsMarketingIcon,
    figureCRM,
    crmBenefitIcon,
    pointIcon,
    point2Icon,
    point3Icon,
    point4Icon,
    contactCenterFullyCustomizableIcon,
    contactCenterApiIntegrationIcon,
    contactCenterAnalyticsReportIcon,
    contactCenterInstantlyOmnichannelIcon,
    contactCenterWallboardIcon,
    contactCenterChatBotIcon,
    contactCenterOmnichannelIcon,
    contactCenterWorkplaceIcon,
    contactCenterHumanResourcesIcon,
    contactCenterHardwareIcon,
  } = props.data

  const voiceFigure = getImage(props.data.voiceFigure)
  const webBasedFigure = getImage(props.data.webBasedFigure)
  const outsourcingFigure = getImage(props.data.outsourcingFigure)

  useEffect(() => {
    switch (i18n.language) {
      case 'id':
        setContent(contentId)
        break
      case 'en':
        setContent(contentEn)
        break
      default:
        setContent(contentEn)
        break
    }
  }, [i18n.language])

  return (
    <>
      <SEO title={content.title} description={content.description} lang={i18n.language}></SEO>
      {/* Start Hero Section */}
      <section className="uk-light">
        <div
          className="uk-section uk-background-norepeat uk-background-cover tm-jascloud-hero-background uk-flex uk-flex-middle"
          uk-height-viewport=""
          uk-img=""
          style={{ minHeight: 'calc(100vh)' }}
        >
          <div className="uk-width-1-1">
            <div className="uk-container">
              <div className="uk-flex uk-flex-between" uk-grid="">
                <div className="uk-width-1-2@m uk-flex uk-flex-middle">
                  <div className="uk-animation-slide-top-medium uk-text-center uk-text-left@m">
                    <img className="uk-hidden@m" width="125" alt="" uk-img={logo.publicURL} />
                    <h1 className="uk-heading-small">{content.title}</h1>
                    <p className="uk-visible@m uk-text-lead">{content.description}</p>
                  </div>
                </div>
                <div className="uk-width-1-2@m uk-flex uk-flex-right uk-visible@m">
                  <img width="" height="" alt="" uk-img={logo.publicURL} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Hero Section */}

      <div
        className="uk-background-norepeat uk-background-center-right"
        // data-src={sectionOneBackground.publicURL}
        uk-img=""
      >
        <section id="voip" className="uk-section">
          <div className="uk-with-1-1">
            <div className="uk-container">
              <div uk-grid="">
                <div className="uk-width-1-3@m uk-grid-item-match uk-flex-middle">
                  <div className="uk-panel">
                    <h2>{content.voice.title}</h2>
                    <p className="uk-text-lead">{content.voice.subtitle}</p>
                  </div>
                </div>
                <div className="uk-width-expand@m">
                  {voiceFigure && <GatsbyImage image={voiceFigure} alt="Voice App" />}
                </div>
              </div>
              <div
                className="uk-grid-small uk-margin-large-top uk-text-center"
                uk-grid=""
                uk-height-match="target: > div > .uk-card"
                // uk-scrollspy="target: > div; cls: uk-animation-fade; delay: 100"
              >
                <div className="uk-width-1-4@m">
                  <div className="uk-card uk-card-default uk-card-body">
                    <img src={officeNumberIcon.publicURL} alt="Office Number" uk-img="" />
                    <h4>{content.voice.office_number.title}</h4>
                    <p>{content.voice.office_number.subtitle}</p>
                  </div>
                </div>
                <div className="uk-width-1-4@m">
                  <div className="uk-card uk-card-default uk-card-body">
                    <img src={bizphoneIcon.publicURL} alt="BizPhone" uk-img="" />
                    <h4>{content.voice.bizphone.title}</h4>
                    <p>{content.voice.bizphone.subtitle}</p>
                  </div>
                </div>
                <div className="uk-width-1-4@m">
                  <div className="uk-card uk-card-default uk-card-body">
                    <img src={siptrunkIcon.publicURL} alt="SIP Trunk" uk-img="" />
                    <h4>{content.voice.sip_trunk.title}</h4>
                    <p>{content.voice.sip_trunk.subtitle}</p>
                  </div>
                </div>
                <div className="uk-width-1-4@m">
                  <div className="uk-card uk-card-default uk-card-body">
                    <img src={virtuallineIcon.publicURL} alt="Virtual Line" uk-img="" />
                    <h4>{content.voice.virtual_line.title}</h4>
                    <p>{content.voice.virtual_line.subtitle}</p>
                  </div>
                </div>
                <div className="uk-width-1-4@m">
                  <div className="uk-card uk-card-default uk-card-body">
                    <img src={directlineIcon.publicURL} alt="Direct Line" uk-img="" />
                    <h4>{content.voice.direct_line.title}</h4>
                    <p>{content.voice.direct_line.subtitle}</p>
                  </div>
                </div>
                <div className="uk-width-1-4@m">
                  <div className="uk-card uk-card-default uk-card-body">
                    <img src={wallboardIcon.publicURL} alt="Wallboard" uk-img="" />
                    <h4>{content.voice.wallboard.title}</h4>
                    <p>{content.voice.wallboard.subtitle}</p>
                  </div>
                </div>
                <div className="uk-width-1-4@m">
                  <div className="uk-card uk-card-default uk-card-body">
                    <img src={callRecordingIcon.publicURL} alt="Call Recording" uk-img="" />
                    <h4>{content.voice.call_recording.title}</h4>
                    <p>{content.voice.call_recording.subtitle}</p>
                  </div>
                </div>
                <div className="uk-width-1-4@m">
                  <div className="uk-card uk-card-default uk-card-body">
                    <img src={smsMarketingIcon.publicURL} alt="SMS Marketing" uk-img="" />
                    <h4>{content.voice.sms_marketing.title}</h4>
                    <p>{content.voice.sms_marketing.subtitle}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <StudyCaseSlider data={voiceStudyCase} language={i18n.language} />
      </div>

      <section id="contact-center" className="uk-section uk-section-small">
        <div className="uk-with-1-1">
          <div className="uk-container">
            <div className="uk-text-center">
              <h2>{content.contact_center.title}</h2>
            </div>
            <div className="uk-section">
              <div className="uk-grid-large" uk-grid="">
                <div className="uk-width-1-2@m">
                  <div className="uk-heading-small uk-text-center">"{content.contact_center.subtitle}"</div>
                </div>
                <div className="uk-width-1-2@m">
                  <div className="uk-column-1-2">
                    <div>
                      <img src={contactCenterFullyCustomizableIcon.publicURL} alt="Fully ustomizable" uk-img="" />
                      <p>{content.contact_center.fully_customizable}</p>
                    </div>
                    <div>
                      <img src={contactCenterApiIntegrationIcon.publicURL} alt="Api Integration" uk-img="" />
                      <p>{content.contact_center.api_integration}</p>
                    </div>
                  </div>
                  <div className="uk-column-1-2">
                    <div>
                      <img src={contactCenterAnalyticsReportIcon.publicURL} alt="Analytics Report" uk-img="" />
                      <p>{content.contact_center.analytics_report}</p>
                    </div>
                    <div>
                      <img
                        src={contactCenterInstantlyOmnichannelIcon.publicURL}
                        alt="Instantly Omnichannel"
                        uk-img=""
                      />
                      <p>{content.contact_center.instantly_omnichannel}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="uk-section">
              <div className="uk-text-center uk-margin">
                <h3>{content.contact_center.number}</h3>
              </div>
              <div className="uk-text-center" uk-grid="">
                <div className="uk-width-1-4@m">
                  <div className="uk-card uk-card-default uk-card-body number-card">
                    <h4 className="uk-article-title">140XY</h4>
                  </div>
                </div>
                <div className="uk-width-1-4@m">
                  <div className="uk-card uk-card-default uk-card-body number-card">
                    <h4 className="uk-article-title">150XYZ</h4>
                  </div>
                </div>
                <div className="uk-width-1-4@m">
                  <div className="uk-card uk-card-default uk-card-body number-card">
                    <h4 className="uk-article-title">1500XYZ</h4>
                  </div>
                </div>
                <div className="uk-width-1-4@m">
                  <div className="uk-card uk-card-default uk-card-body number-card">
                    <h4 className="uk-article-title">0800</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="uk-section">
              <div uk-grid="">
                <div className="uk-width-1-2@m">
                  {webBasedFigure && <GatsbyImage image={webBasedFigure} alt="Voice App" />}
                </div>
                <div className="uk-width-1-2@m">
                  <h3>{content.contact_center.web_based_app.title}</h3>
                  <div className="uk-flex">
                    <img src={contactCenterWallboardIcon.publicURL} alt="Wallboard" uk-img="" />
                    <div>
                      <h4>{content.contact_center.web_based_app.wallboard.title}</h4>
                      <ul>
                        {content.contact_center.web_based_app.wallboard.content.map((item: string, index: number) => (
                          <li key={index}>{item}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="uk-flex">
                    <img src={contactCenterChatBotIcon.publicURL} alt="Chat & bot" uk-img="" />
                    <div>
                      <h4>{content.contact_center.web_based_app.chat_bot.title}</h4>
                      <p>{content.contact_center.web_based_app.chat_bot.content}</p>
                    </div>
                  </div>
                  <div className="uk-flex">
                    <img src={contactCenterOmnichannelIcon.publicURL} alt="Omnichannel" uk-img="" />
                    <div>
                      <h4>{content.contact_center.web_based_app.omnichannel.title}</h4>
                      <p>{content.contact_center.web_based_app.omnichannel.content}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="uk-section">
              <div uk-grid="">
                <div className="uk-width-1-2@m">
                  <h3>{content.contact_center.outsourcing.title}</h3>
                  <div className="uk-flex">
                    <img src={contactCenterWorkplaceIcon.publicURL} alt="Workplace" uk-img="" />
                    <div>
                      <h4>{content.contact_center.outsourcing.workspace.title}</h4>
                      <p>{content.contact_center.outsourcing.workspace.content}</p>
                    </div>
                  </div>
                  <div className="uk-flex">
                    <img src={contactCenterHumanResourcesIcon.publicURL} alt="Human Resources" uk-img="" />
                    <div>
                      <h4>{content.contact_center.outsourcing.human_resources.title}</h4>
                      <p>{content.contact_center.outsourcing.human_resources.content}</p>
                    </div>
                  </div>
                  <div className="uk-flex">
                    <img src={contactCenterHardwareIcon.publicURL} alt="Hardware" uk-img="" />
                    <div>
                      <h4>{content.contact_center.outsourcing.hardware.title}</h4>
                      <p>{content.contact_center.outsourcing.hardware.content}</p>
                    </div>
                  </div>
                </div>
                <div className="uk-width-1-2@m">
                  {outsourcingFigure && <GatsbyImage image={outsourcingFigure} alt="Voice App" />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="crm" className="uk-section uk-section-large">
        <div className="uk-with-1-1">
          <div className="uk-container">
            <div uk-grid="">
              <div className="uk-width-1-2@m">
                <div className="uk-flex uk-flex-center">
                  <img src={figureCRM.publicURL} alt="" uk-img="" />
                </div>
              </div>
              <div
                className="uk-width-1-2@m"
                uk-scrollspy="target: > *; cls: uk-animation-slide-right-small; delay: 100"
              >
                <h2>{content.customer_relationship_management.title}</h2>
                <ul className="uk-list uk-list-disc uk-margin-medium">
                  {content.customer_relationship_management.lists.map((content: any, index: number) => (
                    <li key={index}>{content}</li>
                  ))}
                </ul>
              </div>
            </div>

            <div
              className="uk-grid-small uk-margin-large-top uk-margin-auto"
              uk-grid=""
              uk-height-match="target: > div > .uk-card"
              // uk-scrollspy="target: > div; cls: uk-animation-fade; delay: 100"
            >
              <div className="uk-width-1-4@m">
                <div className="uk-card uk-card-default uk-card-small uk-card-body">
                  <img src={pointIcon.publicURL} width={60} alt="" uk-img="" />

                  <p>{content.customer_relationship_management.pointOne}</p>
                </div>
              </div>
              <div className="uk-width-1-4@m">
                <div className="uk-card uk-card-default uk-card-small uk-card-body">
                  <img src={point2Icon.publicURL} width={60} alt="" uk-img="" />

                  <p>{content.customer_relationship_management.pointTwo}</p>
                </div>
              </div>
              <div className="uk-width-1-4@m">
                <div className="uk-card uk-card-default uk-card-small uk-card-body">
                  <img src={point3Icon.publicURL} width={60} alt="" uk-img="" />

                  <p>{content.customer_relationship_management.pointThree}</p>
                </div>
              </div>
              <div className="uk-width-1-4@m">
                <div className="uk-card uk-card-default uk-card-small uk-card-body">
                  <img src={point4Icon.publicURL} width={60} alt="" uk-img="" />

                  <p>{content.customer_relationship_management.pointFour}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <StudyCaseSlider data={crmStudyCase} language={i18n.language} />
      </section>

      <BottomCta color="#0eadbc" />
      <OtherProducts products={content.other_product} />
    </>
  )
}

export default JascloudPage
